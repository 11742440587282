import React from 'react'
import hero from '../images/hero.png'
import About from './About'
import SocialIcons from './SocialIcons'

export default function Home() {
  return (
    <div className='main'>
      {/* profile icon */}
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'100%'}} alt='phone dog' src={hero}/></div>
      {/* header title */}
      {/* social icon links */}
      <SocialIcons />
      {/* link list */}
      <div style={{display:'flex', flexFlow:'column', marginTop:'8px'}}>
        <a href='https://double-dog.com/' className='button' target="_blank" rel="noopener noreferrer">🐶🐶 double dog shop!</a>
        {/* <a href='https://t.me/huntysdoods' className='button' target="_blank" rel="noopener noreferrer">💬 telegram channel</a> */}
        <a href='https://hmmmmzine.com/' className='button' target="_blank" rel="noopener noreferrer">🎼 music zine site</a>
        <a href='https://patreon.com/huntysdoodles' className='button' target="_blank" rel="noopener noreferrer">📧 free newsletter</a>
        <a href='https://flowerpuppy.online' className='button' target="_blank" rel="noopener noreferrer">🌷🐾 solo music project</a>
        <a href='https://ko-fi.com/huntysdoodles' className='button' target="_blank" rel="noopener noreferrer">💟 virtual tip jar</a>
        {/* <a href='https://huntysdoodles.square.site/s/shop' className='button' target="_blank" rel="noopener noreferrer">🐶 about me</a> */}
        {/* <a href='./archive' className='button'>🎨 art archive</a> */}
        {/* <a href='./commissions' className='button'>✨ commissions</a> */}
        <a href='https://bsky.app/profile/puppy.huntysdoodles.com' className='button'>☁️ blue sky</a>
        <hr style={{borderTop:'4px solid #b4686d', width:'100%', margin:'20px 0'}}/>
        {/* charities */}
        <b>some cool charities:</b>
        <a href='https://www.strivepensacola.com' className='list-links' target="_blank" rel="noopener noreferrer">local NW Florida trans advocacy</a>
        <a href='http://www.transyouthequality.org/donate' className='list-links' target="_blank" rel="noopener noreferrer">trans youth fund</a>
        <a href='https://www.eqfl.org/' className='list-links' target="_blank" rel="noopener noreferrer">florida equality foundation</a>
      </div>
      <hr style={{borderTop:'4px solid #b4686d', width:'100%', margin:'20px 0'}}/>
      {/* about me */}
      <h1 style={{marginTop:'5px'}}>hunty's doodles</h1>
      <About/>
      {/* <div>
        <h2>about me</h2>

        heyo! i’m hunty and i’m a queer artist in the atlanta area! (they/she)<br/><br/>

        my personal work tends to be a reflection of the things i enjoy seeing particularly in the natural, perhaps mundane, world, often featuring anthropomorphic characters and a hint of psychedelia.<br/><br/>

        feel free to contact me via any of my social media DMs or by email, huntysdoodles@gmail.com.
        thanks so much for stopping by! ^^
      </div> */}
    </div>
  )
}
